import { StateService } from './core-shared-module/services/state/state.service';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {NotfoundComponent} from './notfound/notfound.component';
import {AuthService} from './global-module/services/auth/auth.service';
import {ServerErrorComponent} from './server-error/server-error.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'core',
    canLoad: [AuthService],
    canActivateChild: [StateService],
    loadChildren: () => import('./core-module/core.module').then(c => c.CoreModule),
  },
  {
    path: '500',
    component: ServerErrorComponent,
  },
  {
    path: '**',
    component: NotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always', useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
