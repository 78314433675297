import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'atom-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss']
})
export class ServerErrorComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    // this.disconnectService.abandonReconnect();
  }

}
