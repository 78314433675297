import { User } from '../core-shared-module/models/user.model';
import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../global-module/services/auth/auth.service';
import {MenuItem} from 'primeng/api/menuitem';
import {NotificationService} from '../global-module/services/notification/notification.service';
import {Router} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {v4 as uuidv4} from 'uuid';
import {HttpErrorResponse} from '@angular/common/http';
import { UserService } from '../core-shared-module/services/user/user.service';

@Component({
  selector: 'atom-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
  items: MenuItem[];
  activeIndex = 0;
  readyForTFA = false;
  optUrl = '';
  username: FormControl;
  password: FormControl;
  userLoginForm: FormGroup;
  otp: string;
  validateDevice = false;
  userData: User;
  token: any;
  constructor(public auth: AuthService, private notify: NotificationService, private router: Router, private cookieService: CookieService,
              private userService: UserService) {
  }

  ngOnInit() {
    this.items = [
      {label: 'Verify Credentials'},
      {label: 'Two Factor Authentication'}
    ];
    this.checkLoggedIn();
    this.createForm();
  }

  createForm() {
    this.username = new FormControl('', [Validators.required]);
    this.password = new FormControl('', [Validators.required]);
    this.userLoginForm = new FormGroup({
      username: this.username,
      password: this.password
    });
  }

  /***
   * @summary Step one for two factor authentication. Submits user credentials for password flow and reconfigures auth service for
   * two factor authentication on success. Iff the user is not set up for TFA, we present the QR code for their authenticator.
   */
  submitLogin = (formValue) => {
    this.readyForTFA = true;
    return this.auth.login(formValue.username, formValue.password).catch(async (err: HttpErrorResponse) => {
      this.notify.showError(`Error: ${err.error}`);
      this.readyForTFA = false;
      await this.auth.logout();
      this.activeIndex -= 1;
    }).finally(() => {
      this.activeIndex += 1;
    });
  };

  onOtpChange(otp) {
    this.otp = otp;
  }

  /**
   * @summary Step two and final step for authentication into atom portal.
   */
  async submitTFA() {
    await this.auth.tfaLogin(this.otp)
    .catch((err: HttpErrorResponse) => {
        this.notify.showError(`Error: ${err.error}`);
    });
  }

  private checkLoggedIn(): void {
    if (this.auth.isAuthenticated) {
      this.userService.getUserById(this.userService.userId).then(async (result) => {
        this.userData = result;
      })
    } else {
      this.auth.logout().then(r => r);
    }
  }

  deviceValidation() {
    this.validateDevice ? this.cookieService.set('fingerprint', uuidv4()) : this.cookieService.delete('fingerprint');
  }
}
