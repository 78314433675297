import {Injectable} from '@angular/core';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {Topic, WebsocketMessage} from '../../models/websocket-message.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {PlatformLocation} from '@angular/common';
import {NotificationService} from '../../../global-module/services/notification/notification.service';
import {delay, distinctUntilChanged, retryWhen, tap} from 'rxjs/operators';
import {AuthService} from '../../../global-module/services/auth/auth.service';

@Injectable()
export class WebsocketService {
  gatewayId: string;
  switchId: string;
  prefix: string;
  subject: WebSocketSubject<WebsocketMessage>;
  wasDisconnected = false;
  showDisconnectMessage = true;
  private panel$ = new BehaviorSubject<WebsocketMessage>({} as WebsocketMessage);
  private switch$ = new BehaviorSubject<WebsocketMessage>({} as WebsocketMessage);
  private event$ = new BehaviorSubject<WebsocketMessage>({} as WebsocketMessage);
  private user$ = new BehaviorSubject<WebsocketMessage>({} as WebsocketMessage);
  private readonly baseHref: string;

  constructor(private platformLocation: PlatformLocation,
              private notify: NotificationService,
              private auth: AuthService) {
    this.prefix = environment.apiEndpoint;
    this.baseHref = this.platformLocation.getBaseHrefFromDOM();
  }

  get panelSubscription(): Observable<WebsocketMessage> {
    return this.panel$.asObservable();
  }

  get switchSubscription(): Observable<WebsocketMessage> {
    return this.switch$.asObservable();
  }

  get eventSubscription(): Observable<WebsocketMessage> {
    return this.event$.asObservable();
  }

  get userSubscription(): Observable<WebsocketMessage> {
    return this.user$.asObservable();
  }

  set gateway(value: string) {
    this.gatewayId = value;
    this.subscribeToMessages();
  }

  set switch(value: string) {
    this.switchId = value;
  }

  private subscribeToMessages() {
    let endpoint: string;
    this.subject?.unsubscribe();
    if (environment.production) {
      endpoint = `${environment.websocketProtocol}://${window.location.host}${this.baseHref}${this.prefix}/gateways/${this.gatewayId}/ws/${this.auth.access_token}`;
    } else {
      endpoint = `ws://127.0.0.1:8080${this.baseHref}${this.prefix}/gateways/${this.gatewayId}/ws/${this.auth.access_token}`;
    }
    this.subject = webSocket(endpoint);
    this.subject.pipe(
      retryWhen(errors =>
        errors.pipe(
          tap(() => {
            this.wasDisconnected = true;
            if (this.showDisconnectMessage) {
              this.notify.showError('Websocket connection interrupted. Attempting to reconnect...', 1000000000);
              this.showDisconnectMessage = false;
            }
          }),
          delay(1000)
        )
      )
    ).subscribe(
      (message: WebsocketMessage) => {
        if (this.wasDisconnected) {
          this.notify.onReject();
          this.notify.showInfo('Websocket reconnection successful');
          this.wasDisconnected = false;
          this.showDisconnectMessage = true;
        }
        switch (message.topic) {
          case Topic.Gateway:
            this.panel$.next(message);
            break;
          case Topic.Switch:
            this.switch$.next(message);
            break;
          case Topic.Event:
            this.event$.next(message);
            break;
          case Topic.User:
            this.user$.next(message);
            break;
        }
      },
      (err) => {
        console.log(err);
        if (err.name === 'SyntaxError') {
          return;
        }
      },
      () => {
        console.log('complete');
      });
  }
}
