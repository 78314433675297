import {ModuleWithProviders, NgModule} from '@angular/core';
import {FooterComponent} from './components/footer/footer.component';
import {AuthService} from './services/auth/auth.service';
import {CommonModule} from '@angular/common';
import {NotificationService} from './services/notification/notification.service';
import {OAuthModule} from 'angular-oauth2-oidc';
import {ThemeService} from '../core-shared-module/services/theme/theme.service';
import {CookieService} from 'ngx-cookie-service';
import {ToolbarModule} from 'primeng/toolbar';
import { AuthInterceptor } from './services/auth/auth-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [FooterComponent],
  imports: [CommonModule, ToolbarModule, OAuthModule.forRoot({
    resourceServer: {
      sendAccessToken: true
    }
  })],
  exports: [FooterComponent]
})
export class GlobalModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: GlobalModule,
      providers: [
        AuthService, NotificationService, ThemeService, CookieService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
      ]
    };
  }
}
