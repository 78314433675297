import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FeatureControlDirective} from '../../../directives/feature-control/feature-control.directive';
import {Permission} from '../../../models/permissions.model';

@Component({
  selector: 'atom-form-container',
  templateUrl: './form-container.component.html',
  styleUrls: ['./form-container.component.scss'],
  providers: [FeatureControlDirective]
})
export class FormContainerComponent implements OnDestroy {
  @Input() set formGroup(value: FormGroup) {
    if (value) {
      this.form = value;
      this.initAtomForm();
    }
  }

  @Input() submitFunc: (x) => Promise<any>;
  @Input() submitLabel: 'Submit' | 'Apply' | 'Login' = 'Submit';
  @Input() resetLabel: 'Reset' | 'Discard' = 'Reset';
  @Input() buttonPosition: 'flex-start' | 'flex-end' | 'center' | 'unset' = 'flex-end';
  @Input() resetToInitialValue: boolean;
  @Input() switchEditButton: boolean;
  @Input() switchEditButtonDisable: boolean;
  @Input() customButton: boolean;
  @Input() permission: Permission;
  @Input() hasReset = true;
  @Input() style = {};
  @Output() submitted = new EventEmitter();
  @Output() discarded = new EventEmitter();
  @Output() changed = new EventEmitter();
  initialFormValue: object;
  subscription: Subscription;
  form: FormGroup;

  constructor() {
  }

  initAtomForm(): void {
    this.initialFormValue = this.form.getRawValue();
    this.subscription = this.form.valueChanges.subscribe((value) => {
      this.changed.emit(value);
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  /**
   * Though not required, the submit callback should be a returned promise
   * If it is not a promise the callback will still execute but the form will not
   * save a new initial state nor will it be marked as pristine
   */
  submit() {
    if (this.form.valid && !this.switchEditButtonDisable) {
      this.submitted.emit(this.form.getRawValue());
      return this.submitFunc(this.form.getRawValue())?.then(() => {
        this.initialFormValue = this.form.getRawValue();
        this.form.markAsPristine();
      });
    }
  }

  reset() {
    if (this.resetToInitialValue) {
      this.form.reset(this.initialFormValue);
      this.discarded.emit(this.form.getRawValue());
      return;
    }
    this.form.reset();
    this.discarded.emit(this.form.getRawValue());
  }
}
