import {
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {AuthService} from '../../../global-module/services/auth/auth.service';
import {Permission} from '../../models/permissions.model';

@Directive({
  selector: '[atomFeatureControl]'
})
export class FeatureControlDirective {
  private type: 'disable' | 'remove' | 'block';
  private permission: Permission;
  private ref: EmbeddedViewRef<any>;
  private userPermissions: string[];

  @Input()
  set atomFeatureControl(value: Permission) {
    this.permission = value;
    this.process();
  }

  @Input()
  set atomFeatureControlType(value: 'disable' | 'remove' | 'block') {
    this.type = value;
    this.process();
  }

  constructor(private templateRef: TemplateRef<any>,
              private componentFactory: ComponentFactoryResolver,
              private viewContainer: ViewContainerRef,
              private element: ElementRef,
              private renderer: Renderer2,
              private authService: AuthService) {
  }

  process() {
    if (!this.type || !this.permission) {
      this.show();
      return;
    }
  }

  disable() {
    this.element.nativeElement.previousElementSibling.disabled = true;
    this.renderer.addClass(this.element.nativeElement.previousElementSibling, 'overlay');
    this.renderer.setAttribute(this.element.nativeElement.previousElementSibling, 'inert', 'true');
  }

  remove() {
    this.viewContainer.clear();
  }

  block() {
    this.renderer.addClass(this.element.nativeElement.previousElementSibling, 'block-ui');
    this.renderer.setAttribute(this.element.nativeElement.previousElementSibling, 'inert', 'true');
  }

  show() {
    if (this.ref) {
      return;
    }
    this.ref = this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
