import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InPlaceEditFieldComponent} from './components/in-place-edit-field/in-place-edit-field.component';
import {WidgetContainerComponent} from './components/widget-container/widget-container.component';
import {ThemeService} from './services/theme/theme.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LayoutModule} from '@angular/cdk/layout';
import {RouterModule} from '@angular/router';
import {TooltipModule} from 'primeng/tooltip';
import {PasswordModule} from 'primeng/password';
import {DropdownModule} from 'primeng/dropdown';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SliderModule} from 'primeng/slider';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {RadioButtonModule} from 'primeng/radiobutton';
import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {ProgressBarModule} from 'primeng/progressbar';
import {WebsocketService} from './services/websocket/websocket.service';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {CircleProgressOptions, NgCircleProgressModule} from 'ng-circle-progress';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {InPlaceEditDropdownComponent} from './components/in-place-edit-dropdown/in-place-edit-dropdown.component';
import {FormContainerComponent} from './components/form-tools/form-container/form-container.component';
import {FormInputComponent} from './components/form-tools/form-input/form-input.component';
import {PageContainerComponent} from './components/page-container/page-container.component';
import {FormErrorComponent} from './components/form-tools/form-error/form-error.component';
import {FeatureControlDirective} from './directives/feature-control/feature-control.directive';
import {ChartModule} from 'primeng/chart';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {StepsModule} from 'primeng/steps';
import {CardModule} from 'primeng/card';
import {MenuModule} from 'primeng/menu';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {TagModule} from 'primeng/tag';
import {BadgeModule} from 'primeng/badge';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import { ConfirmationService, MenuItem } from 'primeng/api';
import {MessagesModule} from 'primeng/messages';
import {DialogService} from 'primeng/dynamicdialog';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {ChipModule} from 'primeng/chip';
import {KnobModule} from 'primeng/knob';
import {ListboxModule} from 'primeng/listbox';
import {TabViewModule} from 'primeng/tabview';
import {FieldsetModule} from 'primeng/fieldset';
import {ToolbarModule} from 'primeng/toolbar';
import {DataViewModule} from 'primeng/dataview';
import {AccordionModule} from 'primeng/accordion';
import {DialogModule} from 'primeng/dialog';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import {CalendarModule} from 'primeng/calendar';
@NgModule({
  declarations: [
    InPlaceEditFieldComponent,
    WidgetContainerComponent,
    InPlaceEditDropdownComponent,
    FormContainerComponent,
    FormInputComponent,
    FormErrorComponent,
    FeatureControlDirective,
    PageContainerComponent,
    BreadcrumbsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    LayoutModule,
    RouterModule,
    ChartModule,
    ButtonModule,
    InputTextModule,
    StepsModule,
    TooltipModule,
    PasswordModule,
    DropdownModule,
    SliderModule,
    CardModule,
    FontAwesomeModule,
    RadioButtonModule,
    TableModule,
    CheckboxModule,
    MenuModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    ToggleButtonModule,
    NgCircleProgressModule,
    OverlayPanelModule,
    InputMaskModule,
    InputNumberModule,
    MultiSelectModule,
    TagModule,
    BadgeModule,
    ConfirmPopupModule,
    MessagesModule,
    AvatarModule,
    AvatarGroupModule,
    ClipboardModule,
    ChipModule,
    KnobModule,
    SelectButtonModule,
    ListboxModule,
    TabViewModule,
    FieldsetModule,
    ToolbarModule,
    DataViewModule,
    AccordionModule,
    BreadcrumbModule,
    DialogModule,
    CalendarModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    InPlaceEditFieldComponent,
    WidgetContainerComponent,
    FlexLayoutModule,
    LayoutModule,
    ChartModule,
    FontAwesomeModule,
    ChartModule,
    CardModule,
    ButtonModule,
    InputTextModule,
    StepsModule,
    TooltipModule,
    PasswordModule,
    DropdownModule,
    SliderModule,
    RadioButtonModule,
    TableModule,
    CheckboxModule,
    MenuModule,
    ProgressBarModule,
    ToggleButtonModule,
    NgCircleProgressModule,
    OverlayPanelModule,
    InputMaskModule,
    InputNumberModule,
    FormContainerComponent,
    FormInputComponent,
    FormErrorComponent,
    MultiSelectModule,
    FeatureControlDirective,
    InPlaceEditDropdownComponent,
    TagModule,
    BadgeModule,
    ConfirmPopupModule,
    MessagesModule,
    AvatarModule,
    AvatarGroupModule,
    ClipboardModule,
    ChipModule,
    KnobModule,
    PageContainerComponent,
    SelectButtonModule,
    ListboxModule,
    TabViewModule,
    FieldsetModule,
    ToolbarModule,
    DataViewModule,
    AccordionModule,
    BreadcrumbModule,
    BreadcrumbsComponent,
    DialogModule,
    CalendarModule
  ]
})
export class CoreSharedModule {
  constructor(private iconFont: FaIconLibrary) {
    iconFont.addIconPacks(fas);
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreSharedModule,
      providers: [
        ThemeService,
        WebsocketService,
        CircleProgressOptions,
        ConfirmationService,
        DialogService
      ]
    };
  }

}
