export enum FormErrors {
  Required = 'required',
  Email = 'email', // https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
  Min = 'min',
  Max = 'max',
  MinLength = 'minlength',
  MaxLength = 'maxlength',
  Pattern = 'pattern',
  Custom = 'custom'
}
