import { Switch, UserSwitch } from '../../models/devices.model';
import { UserSite, Site } from '../../models/site.model';
import {UserWebsocketMessageType, WebsocketMessage} from '../../models/websocket-message.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActiveUser, Language, Password, User} from '../../models/user.model';
import {UserOrganization} from '../../models/organization.model'
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {WebsocketService} from '../websocket/websocket.service';
import {filter} from 'rxjs/operators';
import { AuthService } from '../../../global-module/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = `${environment.apiEndpoint}/users`;

  constructor(private http: HttpClient, private websocketService: WebsocketService, private auth: AuthService) {
  }

  get userId(): string {
    return sessionStorage.getItem('user_id');
  }

  getCurrentUser() {
    return this.http.get<User>(`${this.baseUrl}/${this.userId}`)
      .toPromise();
  }

  getUserById(userId: string) {
    return this.http.get<User>(`${this.baseUrl}/${userId}`)
      .toPromise();
  }

  getUsers(): Promise<User[]> {
    return this.http.get<User[]>(this.baseUrl)
      .toPromise();
  }

  createUser(userData: any): Promise<any> {
    return this.http.post(this.baseUrl, userData)
      .toPromise();
  }

  updateUser(userData: any): Promise<any> {
    return this.http.post(this.baseUrl, userData)
      .toPromise();
  }

  deleteUser(userId: string): Promise<any> {
    return this.http.delete(`${this.baseUrl}/${userId}`)
      .toPromise();
  }

  resetTfa(userId: string): Promise<any> {
    return this.http.post(`${this.baseUrl}/otp-reset`, JSON.stringify(userId))
      .toPromise();
  }

  resetLockout(userId: string): Promise<any> {
    return this.http.post(`${this.baseUrl}/${userId}/unlock`, JSON.stringify(userId))
      .toPromise();
  }

  changeEmail(email: string): Promise<any> {
    return this.http.post(`${this.baseUrl}/${this.userId}/email`, JSON.stringify(email))
      .toPromise();
  }

  changeDisplayName(data: string): Promise<any> {
    return this.http.post(`${this.baseUrl}/${this.userId}/display-name`, JSON.stringify(data))
      .toPromise();
  }

  changePassword(data: Password): Promise<any> {
    return this.http.put(`${this.baseUrl}/change-password`, data)
      .toPromise();
  }

  newPassword(data: Password): Promise<any> {
    return this.http.post(`${this.baseUrl}/new-password`, data)
      .toPromise();
  }

  changeLanguage(data: Language): Promise<any> {
    return this.http.post(`${this.baseUrl}/language/`, data)
      .toPromise();
  }

  acceptEULA() {
    return this.http.post(`${this.baseUrl}/eula`, {})
      .toPromise();
  }

  unlockUser(id: string) {
    return this.http.post(`${this.baseUrl}/unlock`, JSON.stringify(id))
      .toPromise();
  }

  getActiveUsers(): Promise<ActiveUser[]> {
    return this.http.get<ActiveUser[]>(`${this.baseUrl}/${this.userId}/navigated`)
      .toPromise();
  }

  postActiveUser(user: ActiveUser): Promise<any> {
    return this.http.post(`${this.baseUrl}/${this.userId}/navigated`, user)
      .toPromise();
  }

  subscribeToActiveUser(): Observable<WebsocketMessage> {
    return this.websocketService.userSubscription.pipe(
      filter((message: WebsocketMessage) => message.message === UserWebsocketMessageType.Navigated)
    );
  }

  getApiPermissions(): Promise<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}/permissions`)
      .toPromise();
  }

  // user organization
  getUserOrganizations(userId: string): Promise<UserOrganization[]> {
    return this.http.get<UserOrganization[]>(`${this.baseUrl}/${userId}/organizations`)
      .toPromise();
  }

  addUserToOrganization(data: UserOrganization): Promise<any> {
    return this.http.post(`${this.baseUrl}/${data.userId}/organizations/${data.organizationId}`, {})
      .toPromise();
  }

  removeUserFromOrganization(data: UserOrganization): Promise<any> {
    return this.http.delete(`${this.baseUrl}/${data.userId}/organizations/${data.organizationId}`)
      .toPromise();
  }

  // user site

  getUserSites(userId: string, orgId: string): Promise<Site[]> {
    return this.http.get<Site[]>(`${this.baseUrl}/${userId}/organizations/${orgId}/sites`)
      .toPromise();
  }

  addSiteToUser(data: UserSite): Promise<any> {
    return this.http.post(`${this.baseUrl}/${data.userId}/sites/${data.siteId}`, data)
    .toPromise();
  }

  removeSiteForUser(data: UserSite): Promise<any> {
    return this.http.delete(`${this.baseUrl}/${data.userId}/sites/${data.siteId}`)
      .toPromise();
  }

  // user switch

  addSwitchesToUser(data: UserSwitch): Promise<any> {
    return this.http.post(`${this.baseUrl}/${data.userId}/chargers/${data.switchId}`, {})
      .toPromise();
  }

  removeSwitchesForUser(data: UserSwitch): Promise<any> {
    return this.http.delete(`${this.baseUrl}/${data.userId}/chargers/${data.switchId}`)
      .toPromise();
  }

  getSwitchesForUser(userId: string): Promise<Switch[]> {
    return this.http.get<Switch[]>(`${this.baseUrl}/${userId}/chargers`)
      .toPromise();
  }
}
