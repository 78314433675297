import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginComponent} from './login/login.component';
import {CardModule} from 'primeng/card';
import {LayoutModule} from '@angular/cdk/layout';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GlobalModule} from './global-module/global.module';
import {QRCodeModule} from 'angularx-qrcode';
import {ServerErrorComponent} from './server-error/server-error.component';
import {NotfoundComponent} from './notfound/notfound.component';
import {CoreSharedModule} from './core-shared-module/core-shared.module';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {StepsModule} from 'primeng/steps';
import {MessageService} from 'primeng/api';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ToastModule} from 'primeng/toast';
import {NgOtpInputModule} from 'ng-otp-input';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ServerErrorComponent,
    NotfoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    QRCodeModule,
    GlobalModule.forRoot(),
    CoreSharedModule.forRoot(),
    CardModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    StepsModule,
    ToastModule,
    NgOtpInputModule,
    ProgressSpinnerModule
  ],
  providers: [MessageService],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
