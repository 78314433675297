import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, CanLoad, Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import { TfaTokenResponseModel, TokenContents } from './models/token-response.model';
import jwt_decode from 'jwt-decode';
@Injectable()
export class AuthService implements CanLoad, CanActivate, CanActivateChild {
  refreshTokenTimeout: any;
  constructor(private router: Router, private http: HttpClient) {
    this.startRefreshTokenTimer();
  }

  userLogin(u: string, p: string, headers?: HttpHeaders): Promise<any> {
    return this.http.post(`${environment.tokenEndpoint}`, {username: u, password: p}, {headers})
    .toPromise();
  }

  twillo(authCode: string, headers?: HttpHeaders): Promise<any> {
    return this.http.post(`${environment.optEndpoint}`, JSON.stringify(authCode), {headers})
    .toPromise();
  }

  refresh(headers?: HttpHeaders): Promise<any> {
    return this.http.post(`${environment.refreshEndpoint}`, JSON.stringify(this.refresh_token), {headers})
    .toPromise();
  }

  async login(u: string, p: string, headers?: HttpHeaders): Promise<void> {
    return this.userLogin(u, p, headers).then((resp) => {
      this.setAccessToken(resp);
      return resp;
    })
  }


  async tfaLogin(authCode: string): Promise<TfaTokenResponseModel> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.access_token}`)
    return this.twillo(authCode, headers).then((resp) => {
      this.setAccessToken(resp.accessToken);
      this.setRefreshToken(resp.refreshToken);
      this.startRefreshTokenTimer();
      this.setUserId();
      this.navToUser();
      return resp;
    });
  }

  refreshToken(): Promise<void> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.access_token}`);
    return this.refresh(headers)
    .then((resp) => {
      this.setAccessToken(resp.accessToken);
      this.setRefreshToken(resp.refreshToken);
      this.startRefreshTokenTimer();
      this.setUserId();
    });
  }

  async logout(redirect: boolean = true): Promise<void> {
    sessionStorage.clear();
    this.stopRefreshTokenTimer();
    if (redirect) {
      await this.navToLogin();
    }
  }

  async navToLogin(): Promise<void> {
    await this.router.navigateByUrl('login');
  }

  async navToUser(): Promise<void> {
    await this.router.navigate(['core/user']);
  }

  get access_token(): string {
    const accessToken = sessionStorage.getItem('accessToken');
    return accessToken;
  }

  get refresh_token(): string {
    const refreshToken = sessionStorage.getItem('refreshToken');
    return refreshToken;
  }

  get user_id(): string {
    const userId = sessionStorage.getItem('user_id');
    return userId;
  }


  get access_token_expiration() {
    const decodedToken: TokenContents = jwt_decode(this.access_token);
    return decodedToken.exp;
  }

  get isAuthenticated(): boolean {
    return this.access_token && this.user_id ? true : false;
  }

  private setAccessToken(token: string) {
    sessionStorage.setItem('accessToken', token);
  }

  private setRefreshToken(token: string) {
    sessionStorage.setItem('refreshToken', token);
  }

  private setUserId() {
    const decodedToken: TokenContents = jwt_decode(this.access_token);
    sessionStorage.setItem('user_id', decodedToken.user.id);
  }

  private startRefreshTokenTimer() {
    // set a timeout to refresh the token a minute before it expires
    if (this.access_token) {
      const timeout = (new Date(this.access_token_expiration).getTime() * 1000) - Math.floor((new Date()).getTime()) - (60 * 1000);
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
    }
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
  /**
   * @summary expand on these two route guards when scopes involve different sets of permissions
   */
  // TODO: Uncomment these when auth is figured out
  async canActivate(): Promise<boolean> {
    if (!this.isAuthenticated) {
      await this.logout();
      return false;
    }
    return true;
  }

  async canLoad(): Promise<boolean> {
    if (!this.isAuthenticated) {
      await this.logout();
      return false;
    }
    return true;
  }

  async canActivateChild(): Promise<boolean> {
    if (!this.isAuthenticated) {
      await this.logout();
      return false;
    }
    return true;
  }
}
