
export interface WebsocketMessage {
  id: string;
  topic: Topic;
  message: SwitchWebsocketMessageType | PanelWebsocketMessageType | UserWebsocketMessageType;
  payload: any;
}

export enum Topic {
  Switch = 'switch',
  Gateway = 'gateway',
  Event = 'event',
  Network = 'network',
  User = 'user'
}

export enum SwitchWebsocketMessageType {
  Switch = 'switch',
  Event = 'event',
  Meter = 'meter',
  VoltageClass = 'voltage-class',
  State = 'state',
  Status = 'status',
  Locked = 'locked',
  Online = 'online',
  Feed = 'feed',
  TripCurve = 'trip-curve',
  Name = 'name',
  Protections = 'protections',
  SheddingPriority = 'shedding-priority'
}

export enum PanelWebsocketMessageType {
  Panel = 'panel',
  Event = 'event',
  Meter = 'meter',
  VoltageRating = 'voltage-rating',
  CurrentRating = 'current-rating',
  State = 'state',
  Status = 'status',
  Online = 'online',
  FedFrom = 'fed-from',
  Name = 'name',
  Load = 'panel-load',
  Wiring = 'wiring',
  Phases = 'phases',
  DemandLoadLimit = 'demand-load-limit',
  DemandLimitUnits = 'demand-limit-units',
  DemandReconnectThreshold = 'demand-reconnect-threshold',
  DemandManagementEnabled = 'demand-management-enabled',
  NetworkTrace = 'network-trace',
  NetworkPing = 'network-ping',
  Update = 'update'
}

export enum UserWebsocketMessageType {
  Navigated = 'navigated',
}
