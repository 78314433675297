import { Injectable } from '@angular/core';
import { Observable, ReplaySubject} from 'rxjs';
import {ActivatedRouteSnapshot, CanActivateChild, UrlTree} from '@angular/router';
interface Params {
  organizationId: string;
  siteId: string;
  groupId: string;
}
@Injectable({
  providedIn: 'root'
})
export class StateService implements CanActivateChild {
  private organizationId$ = new ReplaySubject<string>(1);
  private siteId$ = new ReplaySubject<string>(1);
  private groupId$ = new ReplaySubject<string>(1);
  private params: Params;
  constructor() { }

  get organizationId() {
    return this.organizationId$.asObservable();
  }

  get siteId() {
    return this.siteId$.asObservable();
  }

  get groupId() {
    return this.groupId$.asObservable();
  }

  canActivateChild(r: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.params = r.params as Params;
    if (this.params.organizationId) {
      this.organizationId$.next(this.params.organizationId);
    }
    if (this.params.siteId) {
      this.siteId$.next(this.params.siteId);
    }
    if (this.params.groupId) {
      this.groupId$.next(this.params.groupId);
    }
    return true;
  }
}
