import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {OverlayContainer} from '@angular/cdk/overlay';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class ThemeService {
  private THEME_CLASS = 'atom-dark-theme';
  private theme$ = new BehaviorSubject<boolean>(false);
  private darkTheme = false;
  private renderer: Renderer2;
  public themesEnum = modes;

  constructor(private overlayContainer: OverlayContainer, private rendererFactory2: RendererFactory2) {
    this.overlayContainer = overlayContainer;
    this.rendererFactory2 = rendererFactory2;
    this.renderer = this.rendererFactory2.createRenderer(null, null);
  }

  toggleTheme() {
    this.darkTheme = !this.darkTheme;
    if (this.darkTheme) {
      this.overlayContainer.getContainerElement().classList.add(this.THEME_CLASS);
      this.renderer.addClass(document.body, this.THEME_CLASS);
    } else {
      this.overlayContainer.getContainerElement().classList.remove(this.THEME_CLASS);
      this.renderer.removeClass(document.body, this.THEME_CLASS);
    }
    this.theme$.next(this.darkTheme);
  }

  setTheme(mode: string) {
    if (mode === 'light') {
      this.overlayContainer.getContainerElement().classList.remove(this.THEME_CLASS);
      this.renderer.removeClass(document.body, this.THEME_CLASS);
    } else {
      this.THEME_CLASS = mode;
      this.overlayContainer.getContainerElement().classList.add(this.THEME_CLASS);
      this.renderer.addClass(document.body, this.THEME_CLASS);
    }
  }

  get theme(): Observable<any> {
    return this.theme$.asObservable();
  }
}
export enum modes {
  Dark = 'atom-dark-theme',
  Light = 'light'
}
