<fieldset *atomFeatureControl="permission; type:'disable'" [ngStyle]="style" (keydown.enter)="submit()">
  <div class="form-content">
    <ng-content></ng-content>
    <atom-form-error [control]="form"></atom-form-error>
  </div>
  <div *ngIf="!customButton" class="button-group" [style.justify-content]="buttonPosition">
    <button [disabled]="!form.valid || form.pristine || switchEditButtonDisable" (click)="submit()" pButton [label]="submitLabel"
              type="submit" *ngIf="switchEditButton else regular"></button>
    <ng-template #regular>
      <button [disabled]="!form.valid || form.pristine" (click)="submit()" pButton [label]="submitLabel"
              type="submit"></button>
    </ng-template>
    <button *ngIf="hasReset" class="p-button-secondary" [disabled]="form.pristine" pButton (click)="reset()"
            [label]="resetLabel" type="reset"></button>
  </div>
</fieldset>
