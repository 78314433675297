<div class="login-main">
  <p-card>
    <img src="assets/images/atominverted.svg" alt="Photo of an AtomSwitch">
    <div class="steps">
      <p-steps [model]="items" [(activeIndex)]="activeIndex" [readonly]="false"></p-steps>
    </div>
    <div *ngIf="activeIndex == 0">
      <atom-form-container
        *ngIf="userLoginForm"
        [formGroup]="userLoginForm"
        submitLabel="Login"
        [submitFunc]="submitLogin"
        [buttonPosition]="'center'"
        [hasReset]="false">
        <form [formGroup]="userLoginForm">
          <atom-form-input label="Username" [control]="username">
            <input type="text" pInputText [formControl]="username"/>
          </atom-form-input>
          <atom-form-input label="Password" [control]="password">
            <input type="password" pInputText [formControl]="password"/>
          </atom-form-input>
        </form>
      </atom-form-container>
      <p-footer>
      </p-footer>

    </div>
    <div *ngIf="activeIndex == 1">
      <qrcode *ngIf="optUrl" [qrdata]="optUrl"></qrcode>
      <form (ngSubmit)="submitTFA()" (keydown.enter)="submitTFA()">
        <div class="form-group">
          <label for="verification-code">Verification Code</label>
          <br/>
          <ng-otp-input id="verification-code" (onInputChange)="onOtpChange($event)" [config]="{length:6, allowNumbersOnly: true}"></ng-otp-input>
        </div>
        <br/>

        <button pButton type="submit" label="Submit" class="p-button-raised" color="primary" [disabled]="otp?.length < 6"></button>
        <p-footer>
        </p-footer>
      </form>
    </div>

  </p-card>
</div>
<atom-footer></atom-footer>
